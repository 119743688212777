import { Layout } from '@widgets/layout';
import { RequestFeedback } from '@features/request-feedback';
import { bem } from '@lib/bem';
import { t as translate } from '@lib/i18n';
import BWTicker from 'routes/main-page/ui/BWTicker';
import { CTA } from 'routes/main-page/ui/CTA';
import ExtraFeatures from 'routes/main-page/ui/ExtraFeatures';
import Feedback from 'routes/main-page/ui/Feedback';
import { Functional } from 'routes/main-page/ui/Functional';

import HeroSection from 'routes/main-page/ui/HeroSection';
import RoleBenefits from 'routes/main-page/ui/RoleBenefits';
import StatTicker from 'routes/main-page/ui/StatTicker';
import WorkWithIssues from 'routes/main-page/ui/WorkWithIssues';
import { ClientLogos } from '@widgets/client-logos';

import './style.scss';
import { Industries } from '../ui/Industries';
import { Application } from '../ui/Application';
import { Rewards } from '../ui/Rewards';
import { Cases } from '../ui/Cases';
import { Safety } from '../ui/Safety';

const {
  block,
  element,
} = bem('main-page');

const t = (key, blockName = '') => translate(`main_page.${blockName ? `${blockName}.` : ''}${key}`);

function Home() {
  const statTickerItems = t('stat_ticker.items');

  return (
    <Layout
      seo={translate('seo.main')}
      banner
    >
      <div {...block()}>
        <RequestFeedback.Call.Modal />
        <RequestFeedback.Consultation.Modal />
        <RequestFeedback.Problem.Modal />
        <HeroSection t={t} />
        <ClientLogos className="mt-8 mb-4" />
        <Functional />
        <StatTicker tickerItems={statTickerItems} />
        <Industries t={t} />
        <WorkWithIssues t={t} />
        <BWTicker tickerItems={t('benefits_ticker.items')} />
        <RoleBenefits />
        <Application />
        <ExtraFeatures />
        <BWTicker tickerItems={t('benefits_ticker.items')} inverted />
        <Rewards />
        <Feedback t={t} people={t('feedback.people')} />
        <CTA />
        <Cases />
        <Safety />
      </div>
    </Layout>
  );
}

export default Home;
