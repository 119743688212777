import { bem } from '@lib/bem';
import { t } from '@lib/i18n';
import AccordionMenu from '@ui/AccordionMenu';
import MediaQuery from '@ui/MediaQuery';
import { useMediaQuery } from '@hooks/useMediaQuery';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import StaticIcon from '@ui/StaticIcon';
import { ExtraFeatureCard } from '@ui/ExtraFeatureCard';

import './ExtraFeatures.scss';
import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.extra_features.${key}`);
const items = tr('items');

const ExtraFeatures = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  const accordionItems = items.map((x) => ({
    title: <Typography variant="header3" className="my-2">{x.title}</Typography>,
    renderContent: () => <Typography className="text-start text-xs">{x.description}</Typography>,
  }));

  return (
    <section className="mx-3 rounded-t-[32px] border border-b-0 border-transparent border-t-tertiary300 bg-border-gradient-down md-down:mx-0">
      <div 
        className="relative rounded-t-[32px] bg-main-bg-gradient bg-right-top bg-no-repeat py-8 md-down:bg-white"
        style={isMobile ? { backgroundImage: "url('/images/extra-feature-bg-rotated.webp')" } : null}
      >
        <StaticIcon folder="main-page" name="character-besides" className="absolute top-[-83px] right-9 md-down:right-[calc(50%-146px)]" />
        <div className="grid-layout mx-auto text-center md-down:px-3">
          <BlockCaption align={isMobile ? 'left' : 'center'}>{tr('caption')}</BlockCaption>
          <Typography variant="header2" className="mt-4 sm:w-[320px] md-down:text-start">{tr('title')}</Typography>

          <MediaQuery greaterThan="sm">
            <div className="mt-7 grid grid-cols-2 gap-4">
              {items.map((item, index) => (
                <ExtraFeatureCard item={item} key={index} />
              ))}
            </div>
          </MediaQuery>

          <MediaQuery lessThan="md">
            <AccordionMenu items={accordionItems} className="-mx-2 mt-7 rounded-[32px] border border-tertiary300 bg-tertiary200 p-2" />
          </MediaQuery>
        </div>
      </div>
    </section>
  );
};

export default ExtraFeatures;
