import Typography from '@ui/Typography';
import clsx from 'clsx';
import React from 'react';

export const ExtraFeatureCard = ({ item }) => {
  return (
    <div
      className={clsx(
        'rounded-2xl p-4 pr-[120px] text-start shadow-[0px_0px_40px_0px_#C8D6FF8F]',
        'bg-white bg-[length:112px_112px] bg-right-bottom bg-no-repeat',
      )}
      style={{ backgroundImage: "url('/images/extra-feature-bg.webp')" }}
    >
      <Typography variant="header5">{item.title}</Typography>
      <Typography className="mt-2 text-lg">{item.description}</Typography>
    </div>
  );
};
