import Image from '@ui/Image';
import { t } from '@lib/i18n';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import React from 'react';
import Button from '@ui/Button';

const tr = (key) => t(`main_page.cta.${key}`);

export const CTA = () => {
  return (
    <section className="lg:my-8">
      <div className={clsx(
        'lg:grid-layout relative mx-auto rounded-[32px] bg-[linear-gradient(86.51deg,#0B111A_0%,#0B121C_32%,#0C172C_56%,#102F70_78.95%,#1B51C6_100%)] py-8 px-10 text-start',
        'lg-down:mx-3 lg-down:bg-[linear-gradient(177.66deg,#0B111A_22.55%,#0B121C_47.1%,#0C172C_65.51%,#102F70_83.12%,#1B51C6_99.27%)] lg-down:px-4 lg-down:text-center',
        'shadow-[0px_0px_40px_0px_rgba(200,214,255,0.56)]',
      )}
      >
        <Typography variant="header2" color="white">{tr('title')}</Typography>
        <Typography color="white" className="mt-2 lg-only:max-w-[230px]">{tr('subtitle')}</Typography>

        <a href="https://rutube.ru/channel/41001623/" target="_blank" rel="noopener noreferrer" className="mt-5 block">
          <Button reversed className="md-down:w-full">{tr('button')}</Button>
        </a>

        <Image
          src="/images/main-page/cta-main.webp"
          className={clsx(
            'absolute z-10 w-[277px] lg:bottom-0 lg:right-[129px] lg-only:right-[79px]',
            'lg-down:relative lg-down:mx-auto lg-down:-mb-8 lg-down:mt-7',
          )}
        />

        <div className="absolute top-[68px] right-[225px] z-[5] rounded-[28px] bg-[rgba(255,255,255,0.32)] p-0.5 shadow-[0px_16px_64px_0px_#3878FF3D] backdrop-blur-[32px] lg-only:right-[175px] lg-down:hidden">
          <Image src="/images/main-page/cta-push1.webp" className="h-[86px]" />
        </div>
        <div className="absolute bottom-[20px] right-[72px] z-20 rounded-[28px] bg-[rgba(255,255,255,0.32)] p-0.5 shadow-[0px_16px_64px_0px_#3878FF3D] backdrop-blur-[32px] lg-only:right-[22px] lg-down:hidden">
          <Image src="/images/main-page/cta-push2.webp" className="h-[86px]" />
        </div>
        <Image src="/images/main-page/cta-bg.webp" className="absolute inset-y-0 right-0 lg-down:hidden" />
      </div>
    </section>
  );
};
