import {
  useState, useRef, useEffect, useContext, createContext,
} from 'react';
import { useInView } from 'react-intersection-observer';
import { useMediaQuery } from '@hooks/useMediaQuery';
import Image from '@ui/Image';

import { bem } from '@lib/bem';
import clsx from 'clsx';
import Typography from '@ui/Typography';

export const VideoContext = createContext('');

const {
  element,
} = bem('feedback');

const PlayButton = ({ onClick, state }) => {
  return (
    <button 
      type="button" 
      className={clsx(
        'absolute top-2 left-2 z-40',
        'h-min w-min border-none',
        'bg-transparent',
        state && 'hidden active:hidden group-hover:block',
        'visited:outline-none focus:outline-none active:outline-none',
      )}
      onClick={onClick}
    >
      {state === false ? <img alt="" src="/images/play.png" /> : <img alt="" src="/images/pause.png" />}
    </button>
  );
};

const VideoReview = ({
  person: {
    name, link, company, poster, 
  }, 
  videoToggle,
}) => {
  const isMobile = useMediaQuery('(max-width: 687px)');

  const currentVideo = useContext(VideoContext);

  const videoRef = useRef(null);
  const bgVideoRef = useRef(null);

  const { ref, inView } = useInView();

  const [playing, setPlaying] = useState(false);
  const [showPoster, setShowPoster] = useState();

  useEffect(() => {
    if (isMobile) setShowPoster(true);
  }, [isMobile]);

  useEffect(() => {
    if (currentVideo !== name && playing) {
      videoRef.current.pause();
      bgVideoRef.current.pause();
      setPlaying(false);
    }
  }, [currentVideo, name, playing]);

  const videoHandler = () => {
    if (!playing) {
      videoRef.current.play();
      bgVideoRef.current.play();
      setPlaying(true);
      videoToggle(name);
      setTimeout(() => {
        setShowPoster(false);
      }, 500);
    } else {
      videoRef.current.pause();
      bgVideoRef.current.pause();
      setPlaying(false);
      videoToggle('');
    }
  };

  useEffect(() => {
    if (!inView) {
      videoRef.current.pause();
      bgVideoRef.current.pause();
      setPlaying(false);
    }
  }, [inView]);

  const onVideoEnd = () => {
    setPlaying(false);
    videoToggle('');
  };
      
  return (
    <div
      {...element(
        'video-card', 
        {},
        'group',
        'h-[454px] sm:h-[488px] md:h-[454px]',
        'rounded-2xl border border-solid border-tertiary200 focus:border-primary300 hover:pointer',
        'bg-white',
        'relative overflow-hidden',
      )}
      ref={ref}
      onClick={() => videoHandler()}
    >
      <video 
        className="z-0 w-full" 
        src={link}
        ref={bgVideoRef}
        muted
        playsInline
        disablePictureInPicture
        type="video/mp4"
      />
      <div className="absolute inset-0 z-10 backdrop-blur-[3px]" />
      {/* eslint-disable jsx-a11y/media-has-caption */}
      <video 
        className="absolute top-0 left-0 z-20 h-[454px] w-[368px] sm:h-[488px]"
        src={link}
        ref={videoRef}
        onEnded={onVideoEnd}
        playsInline
        disablePictureInPicture
        type="video/mp4"
        preload="metadata"
      />
      
      <div>
        <PlayButton state={playing} />
      </div>

      <div className="absolute inset-0 z-30 bg-[linear-gradient(180deg,rgba(13,19,28,0)_0%,rgba(11,17,26,0.14)_64.58%,rgba(11,17,26,0.64)_82.29%,rgba(11,17,26,0.96)_100%)]" />
      
      {showPoster && <Image className="absolute top-0 left-0 z-30 h-full w-full" src={poster} alt="poster" />}

      <div 
        className={clsx(
          'absolute left-4 bottom-4 z-30 text-left',
        )}
      >
        <Typography className="text-xs uppercase text-white" weight="semibold">
          {name}
        </Typography>
        <Typography className="text-xs uppercase text-white">
          {company}
        </Typography>
      </div>
    </div>
  );
};

export default VideoReview;
