import { bem } from '@lib/bem';
import { createSlot } from '@lib/nano-slots';
import clsx from 'clsx';
import { SlotsProvider } from 'nano-slots';
import styled from 'windstitch';
import StaticIcon from '@ui/StaticIcon';
import './Mockup.scss';
import MediaQuery from '../MediaQuery';

const { block, element } = bem('mockup');

const MockupRoot = styled.div('', {
  variants: {
    sizeDevice: {
      'sm:mobile': 'h-[387px] w-[271px]',
      'md-up:mobile': 'h-[645px] w-[450px]',
      'sm:web': 'h-[386px] w-[276px]',
      'md-up:web': 'h-[567px] w-[434px] mb-[78px] ml-2',
    },
    device: {
      mobile: 'md:left-[20px] sm:left-[10px]',
    },
  },
});

const Mockup = ({
  children,
  className,
  filled,
  device,
  sizeDevice,
  frame,
}) => {
  let mockupIcon = 'mockup';
  
  if (device === 'mobile' && filled) {
    mockupIcon = 'filled-mockup';
  }
  if (device === 'web') {
    mockupIcon = 'md-web-mockup';
    if (sizeDevice.startsWith('sm')) {
      mockupIcon = 'sm-web-mockup';
    }
  }

  const mockupClassname = '!absolute z-10 h-full w-full';

  return (
    <div className={clsx(className, frame && 'rounded-[32px] border border-tertiary400 px-3 pt-7')}>
      <MockupRoot
        className={clsx('!relative')}
        sizeDevice={sizeDevice}
      >
        <MockupRoot
          {...block({}, '!absolute')}
          sizeDevice={sizeDevice}
          device={device}
        >
          <StaticIcon
            name={mockupIcon}
            className={mockupClassname}
            loading="eager"
          />
          {children}
        </MockupRoot>
      </MockupRoot>
    </div>
  );
};

const MockupContent = styled.div('!absolute z-[5]', {
  variants: {
    sizeDevice: {
      'sm:mobile': 'top-[8px] left-[49px] h-[322px] w-[149px]',
      'md-up:mobile': 'top-[14px] left-[81px] h-[536px] w-[247px]',
      'sm:web': 'top-2 left-2 h-[354px] w-[260px]',
      'md-up:web': 'top-2 left-2 h-[534px] w-[418px]',
    },
  },
});

const ScreenSlot = createSlot('mockup-screen');

Mockup.Screen = ({
  children,
  className,
  sizeDevice,
}) => {
  return (
    <MockupContent
      {...element('content', className)}
      sizeDevice={sizeDevice}
    >
      {children}
    </MockupContent>
  );
};

const MockupContainer = ({
  children,
  className,
  filled,
  device = 'mobile',
  visibleAt = ['sm', 'md-up'],
  screenshotClassName,
  noFrame,
}) => {
  const mockupFactory = (breakpoint) => (
    <Mockup
      filled={filled}
      sizeDevice={`${breakpoint}:${device}`}
      className={className}
      device={device}
      frame={!noFrame}
    >
      <Mockup.Screen
        sizeDevice={`${breakpoint}:${device}`}
        className={screenshotClassName}
      >
        <ScreenSlot.Root />
      </Mockup.Screen>
      {children}
    </Mockup>
  );
  
  const viewsMap = {
    sm: (
      <MediaQuery at="sm">
        {mockupFactory('sm')}
      </MediaQuery>
    ),
    'md-up': (
      <MediaQuery greaterThan="sm">
        {mockupFactory('md-up')}
      </MediaQuery>
    ),
  };
  
  if (visibleAt.length === 1) {
    return (
      <SlotsProvider>
        {mockupFactory(visibleAt[0])}
      </SlotsProvider>
    );
  }
  
  return (
    <SlotsProvider>
      {visibleAt.map((breakpoint) => viewsMap[breakpoint])}
    </SlotsProvider>
  );
};

MockupContainer.Screen = ScreenSlot.Plug;

export default MockupContainer;
