import { usePrevious } from '@hooks/usePrevious';
import { bem } from '@lib/bem';

import StaticIcon from '@ui/StaticIcon';
import Image from '@ui/Image';
import List from '@ui/List';
import MediaQuery from '@ui/MediaQuery';
import MobileApps from '@ui/MobileApps';
import { Mockup } from '@ui/Mockup';
import ProgressLine from '@ui/ProgressLine';
import { displayProgress } from '@ui/ProgressLine/ProgressLine';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';

import './WorkWithIssues.scss';
import { useInView } from 'react-intersection-observer';
import { BlockCaption } from '../BlockCaption';

const {
  block,
  element,
} = bem('work-with-issues');

const Rating = ({ number }) => {
  return (
    <div className="row">
      {
        [...Array(number).keys()].map((i) => (
          <StaticIcon key={i} name="star" {...element('rating-star')} />
        ))
      }
    </div>
  );
};

const ItemIcon = ({
  name,
  selected,
  className,
}) => {
  return (
    <div className="!relative h-4 w-4 rounded-lg !drop-shadow-[2px_4px_12px_#E7EEFB]">
      <div className="!h-4 !w-4 rounded-lg !bg-tertiary100 !shadow-[inset_-4px_-4px_8px_#FFFFFF]" />
      <StaticIcon
        name={selected ? `${name}-active` : name}
        folder="main-page"
        className="absolute top-0.5 left-0.5"
      />
    </div>
  );
};

export const JobCard = ({
  item,
  className,
}) => {
  const device = item.web ? 'web' : 'mobile';

  return (
    <div className={clsx(
      'w-full',
      'px-5',
      'pt-5',
      'sm:pb-9 md:pb-[58px]',
      'bg-tertiary200',
      'rounded-[32px]',
      'text-center',
      'flex-col',
      'flex',
      'items-center',
      'border border-tertiary300',
      className,
    )}
    >
      <div className="flex flex-col items-center justify-center">
        <ItemIcon name={item.icon} selected />
        <Typography className="mt-2 flex h-[48px] items-center font-medium">
          {item.title}
        </Typography>
        <Typography variant="body2" className="mt-1 h-[40px]">
          {item.body}
        </Typography>
      </div>
      <Mockup
        className="mt-4"
        device={device}
        visibleAt={['sm']}
        noFrame
      >
        <Mockup.Screen>
          <Image alt={item.image_alt} src={item.image} />
        </Mockup.Screen>
      </Mockup>
    </div>
  );
};

const StageDescriptionRow = ({
  item,
  selected,
  last,
  progressLineRef,
  index,
}) => {
  return (
    <div className="group flex w-full flex-col">
      <div {...element('list-item', {}, 'flex w-full items-center')}>
        <div className="flex flex-col">
          <div className="flex items-center">
            <ItemIcon name={item.icon} selected={selected} />
            <Typography
              weight="medium"
              color="secondary400"
              className="ml-[18px] text-lg"
            >
              {item.title}
            </Typography>
          </div>
          {
            selected ? (
              <Typography color="secondary200" className="mt-0.5 ml-[50px] text-sm">
                {item.body}
              </Typography>
            ) : null
          }
        </div>
        <div className={clsx(selected && '!block', 'ml-auto hidden group-hover:block')}>
          <StaticIcon name="arrow-right" />
        </div>
      </div>
      <ProgressLine
        ref={(el) => {
          // eslint-disable-next-line no-param-reassign
          progressLineRef.current[index] = el;
        }}
        className={clsx(
          '!h-[2px] rounded-full !bg-tertiary300',
          last ? 'mt-1.5' : 'my-1.5',
        )}
        progressIndicatorClassName="!h-[1px]"
      />
    </div>
  );
};

const GROUPS_CHANGING_INTERVAL = 6 * 1000;

const WorkWithIssues = ({
  t,
}) => {
  const blockName = 'work_with_issues';
  const switchItems = t('switch_items', blockName);

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  const progressLineRef = useRef([]);
  const intervalRef = useRef(null);
  const selectedGroupDisplayDuration = useRef(0);

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const previousSelectedItemIndex = usePrevious(selectedItemIndex);

  const displayProgressForSelectedGroup = useCallback((oldSelectedIndex, newSelectedIndex) => {
    const oldLine = progressLineRef.current[oldSelectedIndex];
    const newLine = progressLineRef.current[newSelectedIndex];

    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }

    const displayedDuration = selectedGroupDisplayDuration.current;

    if (!displayedDuration) {
      displayProgress(oldLine, 0);
    }

    const startDate = new Date();
    intervalRef.current = setInterval(() => {
      const diff = displayedDuration + (new Date() - startDate);
      selectedGroupDisplayDuration.current = diff;
      displayProgress(newLine, diff / GROUPS_CHANGING_INTERVAL);
      if (diff >= GROUPS_CHANGING_INTERVAL) {
        clearInterval(intervalRef.current);
        // setTimeout because progress line has transition duration.
        setTimeout(() => {
          if (newSelectedIndex + 1 === switchItems.length) {
            setSelectedItemIndex(0);
          } else {
            setSelectedItemIndex(newSelectedIndex + 1);
          }
        }, 300);
        selectedGroupDisplayDuration.current = 0;
      }
    }, 70);
  }, [switchItems]);

  const selectGroup = (index) => () => {
    selectedGroupDisplayDuration.current = 0;
    setSelectedItemIndex(index);
  };

  useEffect(() => {
    if (inView) {
      displayProgressForSelectedGroup(previousSelectedItemIndex, selectedItemIndex);
    } else if (intervalRef.current !== null) {
      clearInterval(intervalRef.current);
    }
  }, [displayProgressForSelectedGroup, inView, previousSelectedItemIndex, selectedItemIndex]);
  
  const selectedItem = switchItems[selectedItemIndex];

  return (
    <>
      <MediaQuery lessThan="lg">
        <div
          className={
            clsx(
              'flex-column md:grid-layout pb-8 sm:mt-7 md:mx-auto md:mt-8',
              element('slider').className,
            )
          }
        >
          <BlockCaption>
            {t('caption', blockName)}
          </BlockCaption>
          <Typography variant="header2" align="center" className="mt-4 mb-7 sm:mx-3 sm:mt-3">
            {t('title', blockName)}
          </Typography>
          <MediaQuery at="sm">
            <div>
              <div className="mx-1">
                <Slider gap={2}>
                  {
                    switchItems.map((item, i) => (
                      <JobCard item={item} key={i} className="mx-auto" />
                    ))
                  }
                </Slider>
              </div>
              <div className="mx-1 mt-4.5 flex flex-col items-center gap-4 rounded-[32px] border border-tertiary300 bg-tertiary200 py-7 px-3">
                <Typography variant="header5" color="secondary400">
                  {t('mobile.description', blockName)}
                </Typography>
                <MobileApps className="flex " short />
                <div className="flex flex-col items-center gap-2">
                  <Rating number={5} />
                  <div className="flex items-baseline">
                    <Typography variant="header3" weight="medium">
                      {t('mobile.rating', blockName)}
                    </Typography>
                    <Typography variant="subhead1" color="secondary300">
                      {t('mobile.subrating', blockName)}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </MediaQuery>
          <MediaQuery at="md">
            <div>
              <Slider
                settings={{
                  slidesToShow: 2.2,
                  slidesToScroll: 2,
                }}
                gap={2}
              >
                {
                  switchItems.map((item, i) => (
                    <JobCard item={item} key={i} />
                  ))
                }
              </Slider>
              <div className="flex-column-md center mt-6.5 space-y-4 rounded-[32px] border border-tertiary300 bg-tertiary200 py-4 px-2">
                <Typography className="" variant="header5" color="secondary400">
                  {t('mobile.description', blockName)}
                </Typography>
                <MobileApps className="flex space-x-1.5" />
                <div className="row items-center">
                  <Rating number={5} />
                  <Typography variant="header4" weight="medium" className="ml-2">
                    {t('mobile.rating', blockName)}
                  </Typography>
                  <Typography variant="subhead1" className="" color="secondary300">
                    {t('mobile.subrating', blockName)}
                  </Typography>
                </div>
              </div>
            </div>
          </MediaQuery>
        </div>
      </MediaQuery>
      <MediaQuery greaterThan="md">
        <div className="pb-8">
          <div className="row mt-8" ref={ref}>
            <div {...block()}>
              <div className="grid-layout">
                <div className="lg:col-10 p-0 lg:ml-8">
                  <BlockCaption align="left">
                    {t('caption', blockName)}
                  </BlockCaption>
                  <Typography variant="header2" align="left" className="mt-4">
                    {t('title', blockName)}
                  </Typography>
                </div>
                <div className="row mt-7 justify-between gap-2">
                  <div className="grow rounded-[32px] border border-tertiary300 bg-tertiary200 p-10 lg:p-7">
                    <List className="">
                      {switchItems.map((item, i) => (
                        <List.Item onClick={selectGroup(i)} key={i}>
                          <StageDescriptionRow
                            item={item}
                            selected={i === selectedItemIndex}
                            last={i === switchItems.length - 1}
                            progressLineRef={progressLineRef}
                            index={i}
                          />
                        </List.Item>
                      ))}
                    </List>
                  </div>
                  {
                    selectedItem ? (
                      <Mockup
                        {...element('', { web: selectedItem.web }, 'bg-tertiary200 !border-tertiary300')}
                        device={selectedItem.web ? 'web' : 'mobile'}
                      >
                        <Mockup.Screen device={selectedItem.web ? 'web' : 'mobile'}>
                          <Image
                            alt={selectedItem.image_alt}
                            src={selectedItem.image}
                          />
                        </Mockup.Screen>
                      </Mockup>
                    ) : null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="grid-layout row mx-auto mt-2 items-center justify-between rounded-[32px] border border-tertiary300 bg-tertiary200 p-4">
            <div className="row items-center">
              <Rating number={5} />
              <Typography weight="medium" className="ml-3 text-3xl">
                {t('mobile.rating', blockName)}
              </Typography>
              <Typography className="mb-[1px] self-end text-2lg" color="secondary300">
                {t('mobile.subrating', blockName)}
              </Typography>
            </div>
            <MobileApps className="flex space-x-1.5" />
          </div>
        </div>
      </MediaQuery>
    </>
  );
};

WorkWithIssues.propTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkWithIssues;
