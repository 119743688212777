import MediaQuery from '@ui/MediaQuery';
import Slider from '@ui/Slider';
import Typography from '@ui/Typography';
import StaticIcon from '@ui/StaticIcon';
import PersonalReview from '@ui/PersonalReview';
import VideoReview, { VideoContext } from '@ui/VideoReview';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import './Feedback.scss';

import { useEffect, useState } from 'react';
import { BlockCaption } from '../BlockCaption';

const NUMBER_OF_ROWS = 3;
const CARD_PER_ROW = 3;

const ShowMoreButton = ({ onButtonClick }) => {
  return (
    <button
      className={clsx(
        'group flex items-center justify-center',
        'rounded-2xl border border-solid border-tertiary300',
        'bg-white',
      )}
      type="button"
      onClick={onButtonClick}
    >
      <div className="flex gap-1">
        <Typography>Читать больше отзывов</Typography>
        <StaticIcon name="arrow-right" className="rotate-90" />
      </div>
    </button>
  );
};

const Feedback = ({
  t,
  people,
}) => {
  const [showButton, setShowButton] = useState(people.length > CARD_PER_ROW * NUMBER_OF_ROWS);
  const [rows, setRows] = useState(NUMBER_OF_ROWS);
  const [showData, setShowData] = useState(people.slice(0, rows * CARD_PER_ROW));

  const [currentVideo, setCurrentVideo] = useState('');

  useEffect(() => {
    setShowData(people.slice(0, rows * CARD_PER_ROW));

    if (showData.length === people.length) {
      setShowButton(false);
    }
  }, [rows, people, showData.length]);

  const showMoreHandle = () => {
    setRows(rows + 1);
  };

  const videoToggle = (id) => {
    setCurrentVideo(id);
  };

  return (
    <section id="feedback" className="scroll-mt-5 py-8 sm:scroll-mt-15 sm:py-7 md:scroll-mt-11 md:pt-8 md:pb-10">
      <VideoContext.Provider value={currentVideo}>
        <div className="mx-auto sm:mx-3">
          <BlockCaption>{t('feedback.caption')}</BlockCaption>
          <Typography variant="header2" align="center" className="mt-4">
            {t('feedback.title')}
          </Typography>
        </div>
        <MediaQuery greaterThan="md">
          <div className="mx-auto mt-7 grid max-w-[1152px] grid-cols-3 gap-4">
          
            {showData.map((person, i) => {
              if (showButton && i === showData.length - 1) {
                return <ShowMoreButton key={person.name} onButtonClick={showMoreHandle} />;
              }
              const card = person.type === 'text' 
                ? <PersonalReview person={person} key={person.name} /> 
                : <VideoReview person={person} key={person.name} videoToggle={videoToggle} />;
              return card;
            })}
          
          </div>
        </MediaQuery>
        <div className="grid-layout mx-auto lg-down:mt-7">
          <MediaQuery at="md">
            <Slider settings={{ slidesToShow: 1.8 }} gap={4}>
              {people.map((person) => {
                const card = person.type === 'text' 
                  ? <PersonalReview person={person} key={person.name} /> 
                  : <VideoReview person={person} key={person.name} videoToggle={videoToggle} />;
                return card;
              })}
            </Slider>
          </MediaQuery>
          <MediaQuery at="sm">
            <Slider settings={{ slidesToShow: 1 }} gap={2}>
              {people.map((person) => {
                const card = person.type === 'text' 
                  ? <PersonalReview person={person} key={person.name} /> 
                  : <VideoReview person={person} key={person.name} videoToggle={videoToggle} />;
                return card;
              })}
            </Slider>
          </MediaQuery>
        </div>
      </VideoContext.Provider>
    </section>
  );
};

export default Feedback;
