import { Tab } from '@headlessui/react';
import { t } from '@lib/i18n';
import StaticIcon from '@ui/StaticIcon';
import { useMediaQuery } from '@hooks/useMediaQuery';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import { useState } from 'react';

import './RoleBenefits.scss';
import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.role_benefits.${key}`);
const roles = tr('switch_items');

const RoleBenefits = () => {
  const isMobile = useMediaQuery('(max-width: 687px)');
  const [selectedIndex, setSelectedIndex] = useState(0);
  
  return (
    <div className="grid-layout mx-auto py-8 text-center">
      <BlockCaption>
        {tr('caption')}
      </BlockCaption>
      <Typography variant="header2" className="mt-4">
        {tr('title')}
      </Typography>
      
      <div className="mt-7">
        <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
          <Tab.List className="mx-auto flex w-full rounded-2xl border border-tertiary300 bg-tertiary200 p-1 lg:w-fit lg-down:overflow-x-auto">
            {roles.map(({ role_name }, index) => (
              <Tab as="div" className="flex items-center outline-0 focus:shadow-none focus:!outline-none focus:ring-0" key={role_name}>
                {({ selected }) => (
                  <>
                    {!!index && <div className={clsx('h-3 w-[1px] bg-tertiary500', (index === selectedIndex || index === selectedIndex + 1) && '!bg-transparent')} />}
                    <button type="button" className={clsx('group w-max bg-transparent px-2 py-1 outline-0', selected && 'rounded-lg !bg-white shadow-[0px_0px_40px_0px_#C8D6FF8F]')}>
                      <Typography weight="medium" color="secondary100" className={clsx(selected && 'text-secondary400', 'group-hover:text-secondary400')}>
                        {role_name}
                      </Typography>
                    </button>
                  </>
                )}
              </Tab>
            ))}
          </Tab.List>

          <Tab.Panels>
            {roles.map((role, index) => (
              <Tab.Panel
                key={role.role_name}
                className={clsx(
                  'mt-7 min-h-[402px] p-9 sm:mt-4 sm:px-4 sm:pt-6',
                  'lg:pr-[calc(72px+166px)] lg-down:pb-[315px]',
                  'rounded-[32px] bg-white shadow-[0px_0px_40px_0px_#C8D6FF8F]',
                  'relative',
                )}
              >
                <ul className="flex flex-col gap-4">
                  {role.items.map((item) => (
                    <div key={item} className="flex items-start gap-[10px]">
                      <StaticIcon folder="main-page" name="check" className="mt-[5px] w-2" />
                      <Typography className="text-start text-lg">{item}</Typography>
                    </div>
                  ))}
                </ul>
                
                <StaticIcon folder="main-page" name={role.icon} className={clsx('absolute right-9 bottom-0 lg-down:right-[calc(50%-84px)]', index === 2 && 'right-[56px]')} />
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default RoleBenefits;
