import PropTypes from 'prop-types';
import { bem } from '@lib/bem';
import ItemsTicker from '@ui/ItemsTickerNew';
import Typography from '@ui/Typography';
import './StatTicker.scss';

const {
  block,
  element,
} = bem('stat-ticker');

const StatTicker = ({ tickerItems }) => {
  const itemComponents = tickerItems.map((item, index) => {
    return (
      <div key={index} {...element('item-container')}>
        <p {...element('item-value', {}, 'text-primary400')}>
          {item.value}
        </p>
        <Typography
          {...element('item-indicator')}
          variant="subhead1"
          color="secondary400"
        >
          {item.indicator}
        </Typography>
      </div>
    );
  });
  return (
    <div {...block({}, 'py-8 rounded-b-[32px] border-b border-tertiary400')}>
      <div className="">
        <ItemsTicker
          items={itemComponents}
        />
      </div>
    </div>
  );
};

export default StatTicker;
