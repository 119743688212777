import { bem } from '@lib/bem';
import ItemsTicker from '@ui/ItemsTickerNew';

import Typography from '@ui/Typography';
import clsx from 'clsx';
import StarIcon from '../../../../../static/images/four-pointed-star.svg';

import './BWTicker.scss';

const {
  block,
  element,
} = bem('bw-ticker');

const BWTicker = ({ tickerItems, inverted }) => {
  const itemComponents = tickerItems.map((item, index) => {
    return (
      <div key={index} className="flex items-center">
        <Typography weight="regular" transform="uppercase" color={inverted ? 'black' : 'white'} {...element('element', {}, 'text-lg')}>
          {item}
        </Typography>
        <StarIcon className={clsx('mx-[6px] fill-white', inverted && 'fill-[#0B0B0D]')} />
      </div>
    );
  });

  return (
    <div className={clsx(inverted ? 'border-y border-tertiary300 bg-white' : 'bg-[#0B0B0D]')}>
      <ItemsTicker
        tickerProps={{ height: 48, autoFill: true }}
        items={itemComponents}
      />
    </div>
  );
};

export default BWTicker;
