import Typography from '@ui/Typography';
import clsx from 'clsx';

export const BlockCaption = ({
  children, className, align = 'center',
}) => {
  return (
    <div
      className={clsx(
        align === 'center' && 'mx-auto',
        'w-fit rounded-full border border-primary400 px-2 py-[7px] ',
      )}
    >
      <Typography
        transform="uppercase"
        className={clsx('tracking-caption', className)}
        variant="caption2"
        weight="medium"
        color="primary400"
      >
        {children}
      </Typography>
    </div>
  );
};
