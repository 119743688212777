import clsx from 'clsx';
import Typography from '@ui/Typography';
import { t } from '@lib/i18n';
import Slider from '@ui/Slider';
import { SliderCustomButton } from '@ui/SliderCustomButton';
import { useMediaQuery } from '@hooks/useMediaQuery';
import AwardIcon from '../../../../../static/images/main-page/award.svg';
import LaurelIcon from '../../../../../static/images/main-page/laurel.svg';

import { BlockCaption } from '../BlockCaption';

const tr = (key) => t(`main_page.rewards.${key}`);
const items = tr('items');

const colors = {
  teal: {
    stroke: 'stroke-teal400',
    fill: 'fill-teal300',
    ribbon: 'bg-teal400',
  },
  orange: {
    stroke: 'stroke-orange400',
    fill: 'fill-orange300',
    ribbon: 'bg-orange400',
  },
  cyan: {
    stroke: 'stroke-cyan400',
    fill: 'fill-cyan300',
    ribbon: 'bg-cyan400',
  },
  yellow: {
    stroke: 'stroke-yellow400',
    fill: 'fill-yellow500',
    ribbon: 'bg-yellow400',
  },
  purple: {
    stroke: 'stroke-purple400',
    fill: 'fill-purple300',
    ribbon: 'bg-purple400',
  },
};

const Wrapper = ({ children, className, link }) => {
  if (link) {
    return <a href={link} target="_blank" rel="noopener noreferrer nofollow" className={clsx('block', className)}>{children}</a>;
  }

  return <div className={className}>{children}</div>;
};

const Card = ({ item }) => {
  const {
    place, category, company, award, year, color, text_color, link,
  } = item;

  return (
    <Wrapper link={link} className="">
      <div
        className={clsx(
          'relative -z-10 mx-auto h-[274px] w-[224px] px-2 pt-4 text-center',
        )}
        
      >
        <AwardIcon className={clsx('absolute inset-0 -z-20', colors[color].stroke)} />
        <LaurelIcon className={clsx('absolute inset-x-0 top-7 -z-10 m-auto opacity-[0.12]', colors[color].fill)} />
        <div className="flex h-[66px] flex-col gap-0.5">
          <Typography color="secondary200" className="text-2xs">{place}</Typography>
          <Typography weight="medium" className="m-auto text-sm">{category}</Typography>
        </div>

        <div className="relative -ml-4 mt-3 w-fit">
          <div className={clsx(
            'h-5 w-[255px] rounded-[2px] [clip-path:polygon(3%_0,97%_0,100%_50%,97%_100%,3%_100%,0_50%)]',
            colors[color].ribbon,
            'flex items-center justify-center',
          )}
          >
            <Typography weight="medium" className={clsx('text-2xs uppercase', text_color && 'text-white')}>{company}</Typography>
          </div>
        </div>
      
        <Typography className="mt-3 text-2xs">{award}</Typography>
        <Typography weight="medium" className="mt-0.5 text-lg">{year}</Typography>
      </div>
    </Wrapper>
  );
};

export const Rewards = () => {
  const isMobile = useMediaQuery('(max-width: 767px)');

  return (
    <section className="bg-[linear-gradient(180deg,rgba(243,247,255,0)_0%,#F3F7FF_100%)] py-8">
      <div className="grid-layout mx-auto">
        <BlockCaption>{tr('caption')}</BlockCaption>
        <Typography variant="header2" className="mt-4 text-center">{tr('title')}</Typography>
      </div>

      <div className="grid-layout mx-auto mt-5">
        <Slider
          settings={{
            slidesToShow: 5,
            arrows: !isMobile,
            nextArrow: <SliderCustomButton addClassName="!pr-[8px] !pl-[12px] md:!pr-[5px] md:!pl-[7px] !right-[-60px]" />,
            prevArrow: <SliderCustomButton addClassName="!pr-[12px] !pl-[8px] md:!pl-[5px] md:!pr-[7px] !left-[-60px]" />,
            responsive: [
              {
                breakpoint: 1365,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1024,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1,
                  arrows: false,
                },
              },
            ],
            dots: isMobile,
            infinite: isMobile,
          }}
          gap={4}
          className="add-padding"
        >
          {items.map((item, index) => (
            <Card item={item} key={index} />
          ))}
        </Slider>
      </div>
    </section>
  );
};
