const iconColor = {
  teal: '#00CE8C',
  purple: '#9000D3',
  orange: '#D32700',
  cyan: '#00ADD3',
  pink: '#D300AD',
};

const QuoteSvg = ({ fill = 'purple', className = '' }) => {
  return (
    <svg width="110" height="94" viewBox="0 0 110 94" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path opacity="0.04" d="M108.091 22.5815L102.255 44.0544C101.54 47.9694 100.587 56.2738 103.208 64.4597C104.399 67.0696 105.113 70.0355 105.113 73.2389C105.113 84.7465 95.8238 94 84.2714 94C72.719 94 63.4294 84.7465 63.4294 73.2389V72.7647C63.4294 71.3413 63.5482 69.9172 64.025 68.4938C64.6207 66.1213 65.5735 63.9855 66.764 62.088L88.0825 15.583C90.941 7.63448 95.3474 1.82115 103.327 4.78702C111.307 7.39615 111.068 14.6332 108.091 22.5815ZM42.8254 44.0544C42.111 47.9694 41.1581 56.2738 43.7783 64.4597C44.9696 67.0696 45.6841 70.0355 45.6841 73.2389C45.6841 84.7465 36.3945 94 24.842 94C13.2896 94 4 84.7465 4 73.2389V72.7647C4 71.3413 4.1188 69.9172 4.59566 68.4938C5.19131 66.1213 6.14418 63.9855 7.33468 62.088L28.6531 15.583C31.5117 7.63448 35.918 1.82115 43.8976 4.78702C51.8771 7.7529 51.5198 14.8714 48.661 22.9382L42.8254 44.0544Z" fill={iconColor[fill]} />
      <path opacity="0.08" d="M103.623 18.4061L103.614 18.4279L103.608 18.4504L97.7723 39.9233L97.7667 39.9438L97.7629 39.9647C97.0427 43.9111 96.0744 52.3112 98.7315 60.6121L98.7405 60.6403L98.7528 60.6673C99.9159 63.2155 100.613 66.1108 100.613 69.2389C100.613 80.4685 91.5495 89.5 80.2714 89.5C68.9933 89.5 59.9294 80.4685 59.9294 69.2389V68.7647C59.9294 67.3676 60.0464 66.0039 60.4991 64.6526L60.5053 64.6343L60.51 64.6156C61.0921 62.2971 62.0232 60.2095 63.1876 58.3537L63.2049 58.326L63.2186 58.2963L84.537 11.7914L84.5458 11.7721L84.553 11.7522C85.9741 7.80048 87.7538 4.46301 90.1071 2.47398C91.2755 1.4864 92.5815 0.834508 94.0594 0.598658C95.5389 0.362554 97.2227 0.538353 99.1527 1.2557L99.1621 1.25917L99.1715 1.26227C101.1 1.89286 102.501 2.79238 103.485 3.885C104.468 4.97629 105.057 6.28505 105.326 7.771C105.867 10.7644 105.102 14.4564 103.623 18.4061ZM38.3435 39.9212L38.3376 39.9427L38.3336 39.9647C37.6134 43.9111 36.6451 52.3112 39.3021 60.6121L39.3112 60.6403L39.3235 60.6673C40.4866 63.2155 41.1841 66.1108 41.1841 69.2389C41.1841 80.4685 32.1202 89.5 20.842 89.5C9.5639 89.5 0.5 80.4685 0.5 69.2389V68.7647C0.5 67.3676 0.617059 66.0039 1.06976 64.6526L1.0759 64.6343L1.08061 64.6156C1.6627 62.2971 2.59387 60.2095 3.75823 58.3537L3.77559 58.326L3.7892 58.2963L25.1076 11.7914L25.1164 11.7721L25.1236 11.7522C26.5448 7.80048 28.3245 4.46301 30.6777 2.47398C31.8462 1.4864 33.1521 0.834508 34.63 0.598658C36.1096 0.362554 37.7934 0.538353 39.7234 1.2557C41.6553 1.97376 43.0529 2.93173 44.0316 4.06653C45.009 5.19983 45.5891 6.53326 45.8511 8.03566C46.3789 11.0624 45.6124 14.7568 44.1898 18.7712L44.1838 18.7879L44.1791 18.805L38.3435 39.9212Z" stroke={iconColor[fill]} />
    </svg>
  );
};

export default QuoteSvg;
